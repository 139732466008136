import styled from "styled-components"
import { ReactSVG } from "react-svg"
import * as C from "@temp/@next/globalStyles/constants"
import { NavLink } from "../NavLink"

export const MainMenuLinkContainer = styled(NavLink)`
  font-family: ${C.baseFontFamily};
  color: ${C.v2Theme.fontColors.black};
  font-size: ${C.v2Theme.fontSizes.paragraph1};
  font-weight: ${C.v2Theme.fontWeights.semiBold};
  text-decoration: underline;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
`
MainMenuLinkContainer.displayName = "S.MainMenuLinkContainer"

export const MainMenuMoreLink = styled.span`
  display: flex;
  align-items: center;
`
MainMenuMoreLink.displayName = "S.MainMenuMoreLink"

export const MainMenuMoreLinkIcon = styled(ReactSVG)`
  height: 16px;
  width: 16px;
  svg {
    height: 16px;
    width: 16px;
  }
`
MainMenuMoreLink.displayName = "S.MainMenuMoreLink"
