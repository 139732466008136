import styled from "styled-components"

export const SkeletonWrap = styled.div`
  span {
    border-left: none;
    border-right: none;
    border-bottom: none;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 18px;
  }
`
