import { useApolloClient } from "@apollo/client"
import DropdownBody from "@components/atoms/DropdownBody/DropdownBody"
import { MainMenuSubLink } from "@components/atoms/MainMenuSubLink"
import { MenuDropdownProps } from "@temp/@next/components/organisms/MainMenuDropdown/types"
import { mainMenu } from "@temp/components/MainMenu/queries"
import {
  customizeDesktopMenuItems,
  MenuItems,
} from "@temp/components/MainMenu/utils"
import React from "react"

import { MenuDropdownSkeleton } from "../../molecules/MenuDropdownSkeleton"
import * as S from "./styles"

export const MainMenuDropdown: React.FC<MenuDropdownProps> = ({
  itemKey,
  bodyAlign,
  closeMenu,
  id,
  moreItem,
}) => {
  // data fetching
  /* this will not trigger another network request as we already called this
   query(NOTE:Both queries have same name) in parent component using useLazyQuery */

  const apolloClient = useApolloClient()
  const mainMenuItems = apolloClient.readQuery({
    query: mainMenu,
  })

  let chunkedItems
  if (mainMenuItems) {
    const links = customizeDesktopMenuItems(mainMenuItems.shop.navigation.main.items)
    const items =
      moreItem && id === "more" ? [moreItem] : links.filter((item) => item.id === id)
    // eslint-disable-next-line unicorn/no-array-reduce
    const chunked: MenuItems[][] = items[0].children.reduce(
      (resultArray: MenuItems[][], item, index) => {
        const chunkIndex = Math.floor(index / 10)
        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line no-param-reassign
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      },
      []
    )
    chunkedItems = chunked
  }

  return (
    <DropdownBody bodyAlign={bodyAlign} topMargin={36} padding="0">
      <S.MainMenuMenuDropDownOption
        numberOfColumns={chunkedItems ? chunkedItems.length : 2}
      >
        {chunkedItems && mainMenuItems ? (
          chunkedItems.map((chunkedItem, index) => (
            <S.MainMenuMenuDropDownGridWrapper
              key={`MainMenuMenuDropDownOption${itemKey}${chunkedItem[0].id}`}
              isEven={index % 2 === 0}
            >
              {chunkedItem.map((item, subIndex) => (
                <MainMenuSubLink
                  numberOfColumns={chunkedItems ? chunkedItems.length : 2}
                  key={`MainMenuMenuDropDownOptionMainMenuSubLink${itemKey}${item.id}`}
                  item={item}
                  closeMenu={closeMenu}
                  spacer={subIndex < chunkedItem.length - 1}
                />
              ))}
            </S.MainMenuMenuDropDownGridWrapper>
          ))
        ) : (
          <MenuDropdownSkeleton />
        )}
      </S.MainMenuMenuDropDownOption>
    </DropdownBody>
  )
}
