import styled from "styled-components"

import { white, gray3, v2borderRadius2 } from "@temp/@next/globalStyles/constants"

export const MainMenuMenuDropDownOption = styled.div<{
  numberOfColumns: number
}>`
  ${(props) => `
    display: grid;
    @media (min-width: 1366px) {
    grid-template-columns: repeat(${
      props.numberOfColumns > 5 ? "5" : props.numberOfColumns
    }, ${props.numberOfColumns === 1 ? "auto" : "250px"});
    }
    @media screen and (min-width:1024px) and  (max-width:1366px){
      grid-template-columns: repeat(${
        props.numberOfColumns > 5 ? "4" : props.numberOfColumns
      }, ${props.numberOfColumns === 1 ? "auto" : "250px"});
      }
    }
    max-height: 400px;
    overflow-y: scroll;
    border-radius: ${v2borderRadius2};
    &::-webkit-scrollbar {
      display: ${props.numberOfColumns > 5 ? "block" : "none"};
      width: 14px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      
    }

    ::-webkit-input-placeholder,
    ::-webkit-scrollbar-thumb{
        background-clip: padding-box;
        border-radius: 10px;
        border: 4px solid transparent;
        background-color: rgba(118, 135, 161, 0.65);
        cursor: pointer;
        -webkit-transition: background-color 0.1s ease;
        transition: background-color 0.1s ease;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `}
`
MainMenuMenuDropDownOption.displayName = "S.MainMenuMenuDropDownOption"

export const MainMenuMenuDropDownGridWrapper = styled.div<{ isEven: boolean }>`
  ${(props) => `
    background: ${props.isEven ? white : gray3};
    padding: 28px;
    width: auto;
  `}
`
MainMenuMenuDropDownOption.displayName = "S.MainMenuMenuDropDownOption"

export const SkeletonWrap = styled.div`
  span {
    border-left: none;
    border-right: none;
    border-bottom: none;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 18px;
  }
`
