import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

import { MainMenuMenuDropDownGridWrapper } from "../../organisms/MainMenuDropdown/styles"
import { SkeletonWrap } from "./styles"

export const MenuDropdownSkeleton: React.FC = () => {
  return (
    <>
      {[...Array(2)].map((item, index) => (
        <MainMenuMenuDropDownGridWrapper
          key={`MainMenuMenuDropDownOption${index}`}
          isEven={index % 2 === 0}
        >
          {[...Array(10)].map((item, subIndex) => (
            <SkeletonWrap key={subIndex} style={{ position: "relative" }}>
              {index % 2 !== 0 ? (
                <SkeletonTheme color="#ffffff" highlightColor="#f5f5f5">
                  <Skeleton height={18} width={194} />
                </SkeletonTheme>
              ) : (
                <Skeleton height={18} width={194} />
              )}
            </SkeletonWrap>
          ))}
        </MainMenuMenuDropDownGridWrapper>
      ))}
    </>
  )
}
