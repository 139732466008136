import styled from "styled-components"

export const MainMenuLinksContainer = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`

export const MainMenuLinksLinkWrapper = styled.li`
  margin-right: 48px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`
