import { useReactiveVar } from "@apollo/client"
import { BuycepsLogo } from "@components/atoms/BuycepsLogo"
import { BuycepsLogoStyle } from "@components/atoms/BuycepsLogo/types"
import { Divider } from "@components/atoms/Divider"
import MenuAction from "@components/atoms/MenuAction/MenuAction"
import { SearchBar } from "@components/atoms/SearchBar/SearchBar"
import { Spacer } from "@components/atoms/Spacer"
import { checkoutGlobalState } from "@temp/@next/globalStates/Checkout/CheckoutGlobalState"
import { Checkout } from "@temp/@next/globalStates/Checkout/types/Checkout"
import { OverlayContext, OverlayTheme, OverlayType } from "@temp/components/Overlay"
import { getDBIdFromGraphqlId, trackEvent } from "@temp/core/utils"
import React, { useContext } from "react"

import { MainMenuAccountWrapper } from "../MainMenuAccountWrapper/MainMenuAccountWrapper"
import { MainMenuLinkWrapper } from "../MainMenuLinkWrapper"
import * as S from "./styles"
import { MainMenuComponentProps } from "./types"

const getProductData = (checkout: Checkout) => {
  const orderData = checkout && checkout.lines
  return (
    orderData &&
    orderData.map((productData) => ({
      item_name: productData!.variant
        ? productData!.variant.product.name
        : productData!.variation!.comboProduct.name,
      item_id: productData!.variant
        ? getDBIdFromGraphqlId(productData!.variant.id, "ProductVariant")
        : getDBIdFromGraphqlId(productData!.variation!.id, "ComboProductVariation"),
      price: productData!.price!.gross.amount,
      item_brand: productData!.variant
        ? productData!.variant!.product!.brand!.name
        : "None",
      quantity: productData!.quantity,
      item_variant: productData!.variant
        ? productData!.variant.name
        : productData!
          .variation!.variants!.map((variant) => variant!.name)
          .join(" | "),
    }))
  )
}

export const MainMenuDesktop: React.FC<MainMenuComponentProps> = ({
  menuFacadeData,
}: MainMenuComponentProps) => {
  // Contexts
  const overlayContext = useContext(OverlayContext)
  const { cartLines, checkout } = useReactiveVar(checkoutGlobalState)

  let indicatorCount = 0
  if (cartLines?.length > 0) {
    for (const line of cartLines) {
      indicatorCount += line.quantity
    }
  }

  const ViewCartData = {
    ecommerce: {
      items: getProductData(checkout!),
    },
  }

  return (
    <S.MainMenuDesktopContainer>
      <S.MainMenuUpperSection>
        <>
          <S.MainMenuDesktopLogo>
            <BuycepsLogo logoStyle={BuycepsLogoStyle.SMALL} />
          </S.MainMenuDesktopLogo>
          <SearchBar
            searchBarTheme="nav"
            placeholder="Search Brands, Products"
          />
          <Spacer spacerType="vertical" spacerStyle="spacerMedium" />
          <Spacer spacerType="vertical" spacerStyle="quarterSpacer" />
          <MainMenuAccountWrapper />
          <Spacer spacerType="vertical" spacerStyle="spacer" />
          <Spacer spacerType="vertical" spacerStyle="quarterSpacer" />
          <S.MainMenuDesktopMenuActionDividerContainer>
            <Divider dividerType="vertical" marginTop={0} marginBottom={0} />
          </S.MainMenuDesktopMenuActionDividerContainer>
          <Spacer spacerType="vertical" spacerStyle="spacer" />
          <Spacer spacerType="vertical" spacerStyle="halfSpacer" />
          <MenuAction
            iconName="bag"
            title="Cart"
            onClick={() => {
              overlayContext.show(OverlayType.cart, OverlayTheme.right, {})
              trackEvent({
                eventName: "view_cart",
                eventValue: ViewCartData,
                sendToPixel: true,
                sendToDataLayer: true,
              })
            }}
            indicatorCount={indicatorCount}
          />
        </>
      </S.MainMenuUpperSection>
      <S.MainMenuLowerSection isDesktop>
        <MainMenuLinkWrapper menuFacadeData={menuFacadeData!} />
      </S.MainMenuLowerSection>
    </S.MainMenuDesktopContainer>
  )
}
