import styled from "styled-components"
import { baseFontFamily } from "@temp/@next/globalStyles/constants"
import * as C from "@temp/@next/globalStyles/constants"

export const DropdownHeaderLabel = styled.div<{
  isOpen: boolean
  listingPage: boolean
}>`
  ${(props) => `
    font-family: ${baseFontFamily};
    color: ${C.v2Theme.fontColors.black};
    font-size: ${C.v2Theme.fontSizes.paragraph1};
    font-weight: ${C.v2Theme.fontWeights.semiBold};
    text-decoration: underline;
    margin: ${0};
    cursor: pointer;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    
    svg {
      margin-top: ${!props.listingPage ? "4px" : 0};
      margin-left: 4px;
      transform: rotate(${props.isOpen ? "180" : "0"}deg)
    }
    
  `}
`
DropdownHeaderLabel.displayName = "S.DropdownHeaderLabel"

export const DropdownHeaderImg = styled.div<{ listingPage: boolean }>`
  ${({ listingPage }) => `
    margin-top: ${!listingPage && "4px"};
  `}
`
DropdownHeaderImg.displayName = "S.DropdownHeaderImg"
