import styled from "styled-components"
import { MenuStyles } from "@temp/components/MainMenu/utils"
import * as C from "@temp/@next/globalStyles/constants"

const menuSection = {
  sections: {
    lower: {
      background: C.v2Theme.colors.primaryColor1,
      dropDown: {
        grid: {
          background: {
            even: C.v2Theme.backgroundColors.white,
            odd: C.v2Theme.backgroundColors.gray3,
          },
          borderRadius: C.v2Theme.borderRadius.borderRadius2,
          padding: "28px",
        },
      },
      height: "54px",
      links: {
        fontColor: C.v2Theme.fontColors.black,
        fontSize: C.v2Theme.fontSizes.paragraph1,
        fontWeight: C.v2Theme.fontWeights.semiBold,
        iconSize: "16px",
        margin: "0",
        style: {
          header: {
            fontColor: C.v2Theme.fontColors.black,
            fontSize: C.v2Theme.fontSizes.paragraph1,
            fontWeight: C.v2Theme.fontWeights.semiBold,
          },
          normal: {
            fontColor: C.v2Theme.fontColors.gray1,
            fontSize: C.v2Theme.fontSizes.paragraph1,
            fontWeight: C.v2Theme.fontWeights.regular,
          },
        },
      },
      padding: {
        desktop: "0 20px 0 48px",
        mobile: "12px 18px",
      },
      shadow: "none",
    },
    upper: {
      background: C.v2Theme.colors.primaryColor1,
      height: "64px",
      padding: {
        desktop: "0 24px 0 20px",
        mobile: "",
      },
      shadow: C.v2Theme.shadows.shadow2,
    },
  },
}

export const MainMenuSubLinkContainer = styled.div<{
  menuStyle: MenuStyles
  numberOfColumns: number
}>`
  ${(props) => `
    white-space: ${props.numberOfColumns === 1 ? "nowrap" : "normal"};
    font-family: ${C.baseFontFamily};
    color: ${menuSection.sections.lower.links.style[props.menuStyle]?.fontColor};
    font-size: ${menuSection.sections.lower.links.style[props.menuStyle]?.fontSize};
    font-weight: ${
      menuSection.sections.lower.links.style[props.menuStyle]?.fontWeight
    };
  `}
  font-family: ${C.baseFontFamily};
  text-transform: capitalize;
  height: auto;
  width: auto;
  overflow: hidden;
`
MainMenuSubLinkContainer.displayName = "S.MainMenuLinkContainer"

export const MainMenuContainer = styled.div`
  display: flex;
`
MainMenuContainer.displayName = "S.MainMenuContainer"

export const MainMenuPopular = styled.div`
  width: 58px;
  height: 20px;
  border-radius: 7px;
  background-color: #00a1ff;
  padding: 2px 5px 3px;
  margin-left: 15px;
  margin-top: 16px;
`
MainMenuPopular.displayName = "S.MainMenuPopular"

export const MainMenuPopularText = styled.p`
  width: 48px;
  height: 15px;
  font-family: ${C.baseFontFamily};
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
`
MainMenuPopularText.displayName = "S.MainMenuPopularText"

export const MainMenuNew = styled.div`
  width: 40px;
  height: 20px;
  border-radius: 7px;
  background-color: #ff8f00;
  padding: 2px 7px 3px 5px;
  margin-left: 5px;
`
MainMenuNew.displayName = "S.MainMenuNew"

export const MainMenuNewText = styled.p`
  width: undefined;
  height: 15px;
  font-family: ${C.baseFontFamily};
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
`
MainMenuNewText.displayName = "S.MainMenuNewText"
