import DropdownHeader from "@components/atoms/DropdownHeader/DropdownHeader"
import { Spacer } from "@components/atoms/Spacer"
import React from "react"

import * as S from "./styles"
import { MainMenuLinkProps } from "./types"

export const MainMenuLink: React.FC<MainMenuLinkProps> = ({ item }) => (
  <div>
    {item.children && item.children.length > 0 ? (
      item.id !== "more" ? (
        <DropdownHeader isOpen={false}>
          <S.MainMenuLinkContainer prefetch={false} item={item} setStyle={true}>
            {item.name}
          </S.MainMenuLinkContainer>
        </DropdownHeader>
      ) : (
        <S.MainMenuMoreLink>
          <S.MainMenuLinkContainer prefetch={false} item={item} setStyle={true}>
            {item.name}
          </S.MainMenuLinkContainer>
          <Spacer spacerType="vertical" spacerStyle="quarterSpacer" />
          <S.MainMenuMoreLinkIcon src="/images/components/buycepslogo/hamburger.svg" />
        </S.MainMenuMoreLink>
      )
    ) : (
      <S.MainMenuLinkContainer prefetch={false} item={item} setStyle={true}>
        {item.name}
      </S.MainMenuLinkContainer>
    )}
  </div>
)
