import { DividerStyle, DividerType } from "@components/atoms/Divider/types"
import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const DividerContainer = styled.div<{ dividerType: DividerType }>`
  position: relative;
  ${(props) =>
    props.dividerType === "horizontal"
      ? "width:100%;height:auto"
      : "width:auto;height:100%"}
`
const dividerStyle: { [key: string]: any } = {
  divider: {
    account: {
      color: C.v2Theme.backgroundColors.gray2,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "2px",
    },
    large: {
      color: C.theme.divider,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "8px",
    },
    comboProduct: {
      color: C.v2Theme.backgroundColors.gray2,
      size: "1px",
    },
    normal: {
      color: C.v2Theme.backgroundColors.gray1,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "1px",
    },
    voucher: {
      color: C.theme.borderGray,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "1px",
    },
    onlinePaymentSuggestion: {
      color: "#f1f2f3",
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "1px",
    },
    payment: {
      color: C.dividerColorAlt,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "2px",
    },
    paymentTotal: {
      color: C.dividerColorAlt,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "4px",
    },
    tab: {
      color: C.v2Theme.backgroundColors.gray0,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "3px",
    },
    xlarge: {
      color: C.v2Theme.backgroundColors.gray2,
      fontBackgroundColor: C.theme.white,
      fontColor: C.theme.baseFontColor,
      fontSize: C.fontSizeCaptions,
      fontWeight: C.lightFontWeight,
      size: "4px",
    },
    productPagehr: {
      size: "1px",
      color: C.v2Theme.backgroundColors.bluishWhite,
    },
  },
}

export const DividerHr = styled.hr<{
  dividerStyle: DividerStyle
  dividerType: DividerType
  marginTop?: number
  marginBottom?: number
  opacity?: number
}>`
  ${(props) => `
    ${
      props.dividerType === "horizontal"
        ? `
        width: 100%;
        height: ${dividerStyle.divider[props.dividerStyle].size};
      `
        : `
        width: ${dividerStyle.divider[props.dividerStyle].size};
        height: 100%;
      `
    }
    background-color: ${dividerStyle.divider[props.dividerStyle].color};
    margin-top: ${props.marginTop}rem;
    margin-bottom: ${props.marginBottom}rem;
    opacity: ${props.opacity};
  `};
  border: none;
`
DividerHr.displayName = "S.DividerHr"

export const DividerText = styled.h6<{ dividerStyle: DividerStyle }>`
  position: absolute;
  top: 0;
  left: calc(40%);
  ${(props) => `
    font-family: ${C.baseFontFamily};
    font-size: ${dividerStyle.divider[props.dividerStyle].fontSize};
    font-weight: ${dividerStyle.divider[props.dividerStyle].fontWeight};
    color: ${dividerStyle.divider[props.dividerStyle].fontColor};
    background-color: ${
      dividerStyle.divider[props.dividerStyle].fontBackgroundColor
    };
  `};
  padding: 0 1.25rem;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
`
DividerText.displayName = "S.DividerText"
