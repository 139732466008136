import { useLazyQuery } from "@apollo/client"
import { mainMenu } from "@temp/components/MainMenu/queries"
import { MenuItems } from "@temp/components/MainMenu/utils"
import React, { useEffect, useRef, useState } from "react"

import { MainMenuLink } from "../../atoms/MainMenuLink"
import { MainMenuDropdown } from "../MainMenuDropdown/MainMenuDropdown"
import { checkMenuOverFlow, menuHelper } from "../utils"
import * as S from "./styles"

export const MainMenuLinkWrapper: React.FC<{ menuFacadeData: [] }> = ({
  menuFacadeData,
}) => {
  // refs
  const mainMenuReference = useRef(null)
  const [callMainMenuQuery, { data: mainMenuItems }] = useLazyQuery(mainMenu)
  // states
  // re render dropdown when hovering over menu link
  const [openedMenu, setOpenedMenu] = useState<string | null>(null)
  // Re render menu Data when width of links are greater than screen width
  const [menuData, setMenuData] = useState(menuFacadeData)
  // re render when adding "more hamburger" to main menu
  const [moreItem, setMoreItem] = useState(null)

  // side effects
  useEffect(() => {
    if (mainMenuReference && mainMenuReference.current) {
      const { overflow, index } = checkMenuOverFlow(mainMenuReference) || {}
      if (overflow) {
        const { safeLinks, moreMenuItem } = menuHelper(menuFacadeData, index)
        setMenuData(safeLinks)
        setMoreItem(moreMenuItem)
      } else {
        setMenuData(menuFacadeData)
      }
    }
  }, [menuFacadeData])

  // functions
  const handleMouseEntered = (link: MenuItems) => {
    /* fetching main menu query on mouse enter so that this query data is available 
    instantly in the child componnet (NOTE: This will only happen if the queries have same name) */
    if (!mainMenuItems) callMainMenuQuery()
    if (link.children && link.children.length > 0) {
      setOpenedMenu(link.id)
    }
  }

  const closeMenu = () => {
    setOpenedMenu(null)
  }

  return (
    <>
      <S.MainMenuLinksContainer ref={mainMenuReference}>
        {menuData &&
          menuData?.length > 0 &&
          menuData.map((item, index) => (
            <S.MainMenuLinksLinkWrapper
              key={`MainMenuLoader${item.id}`}
              onMouseEnter={() => handleMouseEntered(item)}
              onMouseLeave={closeMenu}
            >
              <MainMenuLink item={item} />
              {openedMenu === item.id && (
                <MainMenuDropdown
                  moreItem={moreItem}
                  itemKey={`link.id${index}`}
                  items={item.children}
                  bodyAlign={index > 2 ? "right" : "left"}
                  closeMenu={closeMenu}
                  id={item.id}
                />
              )}
            </S.MainMenuLinksLinkWrapper>
          ))}
      </S.MainMenuLinksContainer>
    </>
  )
}
