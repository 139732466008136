import React from "react"

import * as S from "./styles"
import { DividerProps } from "./types"

export const Divider: React.FC<DividerProps> = ({
  text,
  marginTop = 0.625,
  marginBottom = 0.625,
  dividerStyle = "normal",
  dividerType = "horizontal",
  opacity = 1,
  ...props
}) => {
  return (
    <S.DividerContainer dividerType={dividerType}>
      <S.DividerHr
        dividerStyle={dividerStyle}
        dividerType={dividerType}
        marginTop={marginTop}
        marginBottom={marginBottom}
        opacity={opacity}
      />
      {text ? <S.DividerText dividerStyle={dividerStyle}>{text}</S.DividerText> : ""}
    </S.DividerContainer>
  )
}
