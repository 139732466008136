import { DropdownHeaderProps } from "@components/atoms/DropdownHeader/types"
import ArrowDown from "public/images/components/buycepslogo/dropdown-arrow.svg"
import React from "react"

import * as S from "./styles"

const DropdownHeader: React.FC<DropdownHeaderProps> = ({
  children,
  onClick,
  isOpen,
  listingPages = false,
}) => {
  return (
    <S.DropdownHeaderLabel
      listingPage={listingPages}
      onClick={onClick}
      isOpen={isOpen}
    >
      {children}
      <S.DropdownHeaderImg listingPage={listingPages}>
        <ArrowDown />
      </S.DropdownHeaderImg>
    </S.DropdownHeaderLabel>
  )
}

export default DropdownHeader
