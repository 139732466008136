import { Spacer } from "@components/atoms/Spacer"
import { getLinkForMenuItem } from "@temp/components/MainMenu/utils"
import Link from "@temp/core/link"
import React from "react"

import * as S from "./styles"
import { MainMenuSubLinkProps } from "./types"

export const MainMenuSubLink: React.FC<MainMenuSubLinkProps> = ({
  item,
  closeMenu,
  spacer,
  numberOfColumns,
}) => (
  <>
    <S.MainMenuSubLinkContainer
      numberOfColumns={numberOfColumns}
      menuStyle={item.style}
    >
      <Link prefetch={false} href={getLinkForMenuItem(item)}>
        <a onClick={closeMenu}>
          {(item.id && item.id === "TWVudUl0ZW06MjIz") ||
          item.id === "QnJhbmQ6OTY" ? (
            <S.MainMenuContainer>
              {item.name.slice(0, 16)}
              <br />
              {item.name.slice(16)}
              {item.id && item.id === "TWVudUl0ZW06MjIz" && (
                <S.MainMenuPopular>
                  <S.MainMenuPopularText>Popular</S.MainMenuPopularText>
                </S.MainMenuPopular>
              )}
              {/* {item.id && item.id === "QnJhbmQ6OTY" && (
                <S.MainMenuNew>
                  <S.MainMenuNewText>New</S.MainMenuNewText>
                </S.MainMenuNew>
              )} */}
            </S.MainMenuContainer>
          ) : (
            <S.MainMenuContainer>{item.name}</S.MainMenuContainer>
          )}
          {item.children && item.children.length > 0
            ? item.children.map((childItem, index) => (
                <div key={`MainMenuSubLinkContainer${item.id}${childItem.id}`}>
                  <MainMenuSubLink item={childItem} closeMenu={closeMenu} />
                  {index < item.children.length - 1 ? (
                    <Spacer spacerStyle="spacer" />
                  ) : null}
                </div>
              ))
            : null}
        </a>
      </Link>
    </S.MainMenuSubLinkContainer>
    {spacer ? <Spacer spacerStyle="spacer" /> : null}
  </>
)
