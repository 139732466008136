import { menuListBreakpoint } from "../../constants"
import { alphabetalizeMenuItems } from "../../core/utils"
import { MainMenu_shop_navigation_main_items } from "./types/MainMenu"
import { MainMenuSubItem_parent } from "./types/MainMenuSubItem"

export type MenuStyles = "header" | "normal"

export interface AlphabetizedArray {
  resultArray: []
}

export interface MenuItems {
  id: string
  name: string
  children: MenuItems[]
  style: MenuStyles
  parent: MainMenuSubItem_parent | null
  finalUrl: string | null
}

export interface CategoryDetails {
  products: any
  id: string
  name: string
}

export interface Categories {
  node: CategoryDetails
}

// Function to fetch which link to be redirected to
export const getLinkForMenuItem = (link: MenuItems) => {
  let urlToBeRedirectedTo = "/"
  if (link.finalUrl) {
    urlToBeRedirectedTo = link.finalUrl
  }
  return urlToBeRedirectedTo
}

export const customizeDesktopMenuItems = (
  items: MainMenu_shop_navigation_main_items[] | null
) => {
  const customizedItems: MenuItems[] = []
  items &&
    items.map((item) => {
      if (item.children.length > menuListBreakpoint) {
        const letterBrandArray: MenuItems[] = []

        const itemChildren = [...item.children]
        itemChildren.sort((a, b) => a.name.localeCompare(b.name))
        const alphabetizedArray = alphabetalizeMenuItems(
          itemChildren,
          menuListBreakpoint
        )
        if (alphabetizedArray.featuredArray.length > 0) {
          letterBrandArray.push({
            children: [],
            id: `-${-10}`,
            name: `Featured ${item.name}`,
            parent: null,
            style: "header",
            finalUrl: "",
          })
          alphabetizedArray.featuredArray.map((featuredItem) => {
            letterBrandArray.push({
              children: [],
              id: featuredItem.id,
              name: featuredItem.name.toLowerCase(),
              parent: featuredItem.parent,
              style: "normal",
              finalUrl: featuredItem.finalUrl,
            })
          })
        }
        alphabetizedArray.resultArray.map((child, index) => {
          letterBrandArray.push({
            children: [],
            id: `-${index}`,
            name: `${child[0].name[0]}-${child[child.length - 1].name[0]}`,
            parent: null,
            style: "header",
            finalUrl: "",
          })
          child.map((subchild) => {
            letterBrandArray.push({
              children: [],
              id: subchild.id,
              name: subchild.name.toLowerCase(),
              parent: subchild.parent,
              style: "normal",
              finalUrl: subchild.finalUrl,
            })
          })
        })
        customizedItems.push({
          children: letterBrandArray,
          id: item.id,
          name: item.name.toLowerCase(),
          parent: item.parent,
          style: "normal",
          finalUrl: item.finalUrl,
        })
      } else {
        const children: MenuItems[] = []
        const itemChildren = [...item.children]
        itemChildren.sort((a, b) => a.name.localeCompare(b.name))
        itemChildren.map((child) => {
          children.push({
            children: [],
            id: child.id,
            name: child.name.toLowerCase(),
            parent: child.parent,
            style: child.children && child.children.length > 0 ? "header" : "normal",
            finalUrl: child.finalUrl,
          })
          if (child.children) {
            const childChildren = [...child.children]
            childChildren.sort((a, b) => a.name.localeCompare(b.name))
            childChildren.map((subChild) => {
              children.push({
                children: [],
                id: subChild.id,
                name: subChild.name.toLowerCase(),
                parent: subChild.parent,
                style: "normal",
                finalUrl: subChild.finalUrl,
              })
            })
          }
        })
        customizedItems.push({
          children,
          id: item.id,
          name: item.name.toLowerCase(),
          parent: item.parent,
          style: "normal",
          finalUrl: item.finalUrl,
        })
      }
    })
  return customizedItems
}

export const customizeMobileMenuItems = (
  items: MainMenu_shop_navigation_main_items[] | null
) => {
  const customizedItems: MenuItems[] = []
  items &&
    items.map((item) => {
      if (item.children.length > menuListBreakpoint) {
        const letterBrandArray: MenuItems[] = []

        const itemChildren = [...item.children]
        itemChildren.sort((a, b) => a.name.localeCompare(b.name))
        const alphabetizedArray = alphabetalizeMenuItems(
          itemChildren,
          menuListBreakpoint
        )
        if (alphabetizedArray.featuredArray.length > 0) {
          letterBrandArray.push({
            children: alphabetizedArray.featuredArray,
            id: `-${-10}`,
            name: `Featured ${item.name}`,
            parent: null,
            style: "header",
            finalUrl: "",
          })
        }
        alphabetizedArray.resultArray.map((child, index) => {
          letterBrandArray.push({
            children: child,
            id: `-${index}`,
            name: `${child[0].name[0]}-${child[child.length - 1].name[0]}`,
            parent: null,
            style: "header",
            finalUrl: "",
          })
        })
        customizedItems.push({
          children: letterBrandArray,
          id: item.id,
          name: item.name.toLowerCase(),
          parent: item.parent,
          style: "normal",
          finalUrl: item.finalUrl,
        })
      } else {
        const children: MenuItems[] = []
        const itemChildren = [...item.children]
        itemChildren.sort((a, b) => a.name.localeCompare(b.name))
        itemChildren.map((child) => {
          children.push({
            children: [],
            id: child.id,
            name: child.name.toLowerCase(),
            parent: child.parent,
            style: child.children && child.children.length > 0 ? "header" : "normal",
            finalUrl: child.finalUrl,
          })
          if (child.children) {
            const childChildren = [...child.children]
            childChildren.sort((a, b) => a.name.localeCompare(b.name))
            childChildren.map((subChild) => {
              children.push({
                children: [],
                id: subChild.id,
                name: subChild.name.toLowerCase(),
                parent: subChild.parent,
                style: "normal",
                finalUrl: subChild.finalUrl,
              })
            })
          }
        })
        customizedItems.push({
          children,
          id: item.id,
          name: item.name.toLowerCase(),
          parent: item.parent,
          style: "normal",
          finalUrl: item.finalUrl,
        })
      }
    })
  return customizedItems
}
